.chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .chat-history {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: black;
  }
  
  .message {
    width: fit-content; /* Dynamic width based on content length */
    max-width: 70%; /* Wrap at half screen width */
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 8px;
    align-self: flex-end; /* Align user messages to the right */
    background-color: black;
    border: 1px solid white;
    color: white;
  }
  
  .user {
    align-self: flex-end;
    background-color: black;
    margin-left: auto;
    border: 1px solid white;
    color: white;
  }
  
  .bot {
    align-self: flex-start;
    background-color: black;
    border: 1px solid white;
    color: white;
  }
  
  .user-input-container {
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Right-align the content */
    padding: 10px;
    border-top: 1px solid #ccc;
  }
  
  input {
    background-color: black;
    border: 1px white;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    outline: none;
    width: 100%;
    color: white;
  }
  
  .submit-button {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  .header {
    color: white;
    background-color: black;
    text-align: center;
    padding: 10px;
  }
  
  .header div {
    display: flex;
    align-items: center;
    justify-content: center;
  }